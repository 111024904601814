<template>
  <div>
    <v-data-table
      dense
      :search="search"
      loading-text="loading..."
      no-data-text="no credit transaction available"
      :headers="headers"
      :items="tableItems"
      :items-per-page="10"
      :loading="loading"
    >
      <!-- inherent table search functionality -->
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <!-- convert start date -->
      <template v-slot:[`item.startedAt`]="{ item }">
        <span>{{ new Date(item.startedAt).toLocaleString() !== 'Invalid Date' 
        ? new Date(item.startedAt).toLocaleString() : '-'}}</span>
      </template>
      <!-- convert end date -->
      <template v-slot:[`item.finishedAt`]="{ item }">
        <span>{{ new Date(item.finishedAt).toLocaleString() !== 'Invalid Date' 
        ? new Date(item.finishedAt).toLocaleString() : '-'}}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      /* table search */
      search: '',
    }
  },

  methods: {
    ...mapActions('credits', ['loadCreditTransactions']),
    ...mapActions('backend', ['load']),
  },

  computed: {
    ...mapGetters('credits', ['getTransactionsTable']),
    ...mapState('backend', {
        processes: (state) => state.processes,
    }),

    tableItems(){
        return this.getTransactionsTable(this.processes)
    },

    headers() {
      return [
        { text: 'Job', align: 'start', value: 'job' },
        { text: 'Activity', value: 'activity' },
        { text: 'Start', value: 'startedAt' },
        { text: 'End', value: 'finishedAt' },
        { text: 'Status', value: 'status' },
        { text: 'Amount', value: 'amount' },
      ]
    },
  },

  async created() {
    this.loading = true
    await this.loadCreditTransactions()
    await this.load(['process'])
    this.loading = false
  },
}
</script>

<style lang="scss" scoped></style>
